/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';

import { ReactComponent as NotFoundIcon } from '../../images/icons/NotFound-page_webshop-nl.svg';

import styles from './NotFoundPage.module.scss';

const Footer = loadable(() => import('../Footer/Footer'));
const NewsletterSection = loadable(() => import('../NewsletterSection/NewsletterSection'));
const RecentlyViewedProducts = loadable(() => import('../RecentlyViewedProducts/RecentlyViewedProducts'));
const StickyHeader = loadable(() => import('../StickyHeader/StickyHeader'));
const Button = loadable(() => import('../Button/Button'));

const NotFoundPage = () => {
  const intl = useIntl();
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'notFound.title' })}</title>
        <script>window.__SSR_IS_404__ = true</script>
      </Helmet>
      <StickyHeader />
      <div className={classNames('container')} id="readyForCrawler">
        <div className="row">
          <div className={classNames(styles.container, 'col-12-xs')}>
            <NotFoundIcon />
            <h3 data-testid="NotFoundPageTitle">
              <FormattedMessage id="notFound.title" defaultMessage="Page not found" />
            </h3>
            <p>
              <FormattedMessage
                id="notFound.description"
                defaultMessage="Oops. It looks like this page has been moved, renamed, or does not exist anymore"
              />
            </p>
          </div>
          <Link to="/" className={classNames(styles.goHomeLink, 'col-12-xs offset-2-m col-8-m offset-4-xl col-4-xl')}>
            <Button classList={{ root: classNames(styles.button) }} variation="secondary">
              <FormattedMessage id="notFound.link" defaultMessage="Go back to the homepage" />
            </Button>
          </Link>
        </div>
      </div>
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
    </>
  );
};

export default NotFoundPage;
